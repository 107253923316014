import { SendMessagePayload } from 'src/app/models/wsSendMessage';
import { NavigateBackEventConfirm, NavigateBackEventStart, NavigateBackEventWait, ProcessDataEvent, ReloadTaskBasicInfoEvent, resetTimerEvent, TaskBasicInfoEvent } from './../../services/EventEmitters';
import { FileInfoService } from './../../services/fileInfo.service';
import { ChangeDetectorRef, Component, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IFileInfo } from 'src/app/models/file-info.interface';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { filter, finalize, map, Observable, Subscription, timer } from 'rxjs';
import { getUserInfo, hideSidebar, ipsWsMessages$, showSidebar, wsStatus$, ws_sendMessage } from '@igps/client-root-config';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastComponent } from '@ui-core/toast';
import { onChangeProcess, selectedTaskId, EmailMessageId } from '@igps/top-utility';
import { Events } from 'src/app/models/Events';
import { TaskStatus } from 'src/app/enums/task-status';
import { ProcessService } from 'src/app/services/process.service';
import { ProcessDataModel } from 'src/app/models/processDataModel';
import { TaskBasicInfoDataModel } from 'src/app/models/taskBasicInfoDataModel';

export let fileInfo!: IFileInfo;
export let processData!: ProcessDataModel;

@Component({
  selector: 'app-file-info-header',
  templateUrl: './file-info-header.component.html',
  styleUrls: ['./file-info-header.component.scss']
})
export class FileInfoHeaderComponent implements OnInit, OnDestroy {

  constructor(private fileInfoService: FileInfoService, private route: ActivatedRoute, private router: Router, private cdref: ChangeDetectorRef, private zone: NgZone,private processService: ProcessService) { }


  isAlreadyCancelled: boolean = false;
  basicTaskInfo!: TaskBasicInfoDataModel;

  ngOnDestroy(): void {
    this.taskIdSubscription.unsubscribe();
    this.reloadBasicFileInfoSubscription.unsubscribe();
    this.NavigateBackEventWaitSubscription.unsubscribe();
    this.NavigateBackEventConfirmSubscription.unsubscribe();
    this.WebSockectSubscription.unsubscribe();
    this.wsStatusSubscription.unsubscribe();
    if (this.navigateTimerSubscription) {
      this.navigateTimerSubscription.unsubscribe();
    }
    (fileInfo as any) = null;
    (processData as any) = null;
  }

  showSpinner: boolean = false;
  isError!: boolean;
  errorMessage!: string;
  processData!:ProcessDataModel;
  @ViewChild(ToastComponent) toaster!: ToastComponent;

  fileInfo!: IFileInfo;
  taskId!: string;
  taskId$!: Observable<string>;
  taskIdSubscription!: Subscription;
  taskInformationSubscription$!: Subscription;
  reloadBasicFileInfoSubscription!: Subscription;
  NavigateBackEventWaitSubscription!: Subscription;
  NavigateBackEventConfirmSubscription!: Subscription;
  WebSockectSubscription!: Subscription;
  wsStatusSubscription!: Subscription;

  isExpanded: boolean = true;
  navigateTimerSubscription!: Subscription;
  ngOnInit(): void {
    showSidebar();
    this.taskId$ = this.route.paramMap.pipe(
      map((params: ParamMap) => params.get('id')),
      filter(param => param != null)
    ) as Observable<string>;
    this.taskIdSubscription = this.taskId$.subscribe(id => {
      this.taskId = id;
      this.getTaskInfo(false);
    });
    this.NavigateBackEventWaitSubscription = NavigateBackEventWait.subscribe(() => {
      if (this.navigateTimerSubscription) {
        this.navigateTimerSubscription.unsubscribe();
      }
    });

    this.NavigateBackEventConfirmSubscription = NavigateBackEventConfirm.subscribe(() => {
      if (this.navigateTimerSubscription) {
        this.navigateTimerSubscription.unsubscribe();
      }
      this.navigate();
    });
    this.reloadBasicFileInfoSubscription = ReloadTaskBasicInfoEvent.subscribe(() => {
      this.getTaskInfo(false);
    });
    this.WebSockectSubscription = ipsWsMessages$.subscribe(data => {
      if (data) {
        if (Events.TriggerEvents.includes(data?.EventName?.toLowerCase()) || Events.IgniteErrorEvent.includes(data?.EventName?.toLowerCase())) {
          selectedTaskId.emit(this.taskId);
          this.getTaskInfo(true);
          if (Events.IgniteErrorEvent.includes(data?.EventName?.toLowerCase())) {
            this.zone.run(() => {
              this.openToast("Action Error from Ignite. See Activity Log for more details", true, true);
            });
          }
        }
        else if (Events.NotesEvent.includes(data?.EventName?.toLowerCase()) || Events.DocumentEvent.includes(data?.EventName?.toLowerCase())) {
          selectedTaskId.emit(this.taskId);
          if(this.fileInfo.emailId){
            EmailMessageId.emit(this.fileInfo.emailId);
          }
        }
      }
    });
    this.wsStatusSubscription = wsStatus$.subscribe((event: { status: string }) => {
      if (event.status.toUpperCase() === "CONNECTED") {
        this.sendwsMessage();
      }
    });
  }

  sendwsMessage() {
    const payload: SendMessagePayload = {
      activePage: "task",
      processId: this.fileInfo?.processId,
      taskId: this.fileInfo?.id
    };
    ws_sendMessage(payload);
  }

  getTaskInfo(isBackground: boolean) {
    resetTimerEvent.emit();
    this.zone.run(() => {
      if (!isBackground) {
        this.showSpinner = true;
      }
      this.fileInfoService.getFileInfo(this.taskId)
        .pipe(
          finalize(() => {
            this.showSpinner = false;
          })
        ).subscribe({
          next: (d) => {
            fileInfo = d;
            fileInfo.fileStatus.value = fileInfo.fileStatus?.value?.toLowerCase();
            this.fileInfo = fileInfo;
            if(this.fileInfo.emailId){
              EmailMessageId.emit(this.fileInfo.emailId);
            }
            selectedTaskId.emit(this.taskId);
            TaskBasicInfoEvent.emit({ isBackgroundRefresh: isBackground, taskinfo: this.fileInfo });
            if (!isBackground) {
              this.sendwsMessage();
              this.getProcessData(this.fileInfo?.processId);
              this.getStatusAlerts();
            }
            if (this.fileInfo.taskStatus.id === TaskStatus.CANCELLED) {
              this.openToast("This task has been cancelled", true, true);
              this.cdref.detectChanges();
              this.isAlreadyCancelled = true;
            }
          },
          error: (error: HttpErrorResponse) => {
            this.openToast(error?.error.Detail, true);
          }
        });
    })
  }

  getStatusAlerts(){
    this.fileInfoService.getStatusAlert(this.fileInfo?.processId, this.fileInfo.fileStatus.id).pipe(
      map(data => {
        if(data){
          this.openToast(JSON.parse(JSON.stringify(data)),false, false);
        }
      })
    ).subscribe();
  }

  getProcessData(processId: string){
    this.processService.getProcessById(processId).pipe(
      map(res => {
        processData = res;
        this.processData = res;
        ProcessDataEvent.emit(res);
        onChangeProcess.emit({id:res.id, taskSource:res.taskSource ,name: res.name, processorAddTask : res.processorAddTask});
      })
    ).subscribe();
  }

  ontogglePanel() {
    if (this.isExpanded) {
      hideSidebar();
    }
    else {
      showSidebar();
    }
    this.isExpanded = !this.isExpanded;
  }

  onBack() {
    this.navigateTimerSubscription = timer(500).subscribe(() => this.navigate());
    NavigateBackEventStart.emit();
  }

  navigate() {
    const userInfo = getUserInfo();
    if (userInfo.assumedRoleId == 1) {
      this.router.navigate(['manager/dashboard']);
    }
    else {
      this.router.navigate(['processor/dashboard']);
    }
  }

  openToast(message: string, isError: boolean, isSticky: boolean = false) {
    this.isError = isError;
    this.errorMessage = message;
    if (isSticky) {
      this.toaster.toastDuration = 600000;
    }
    this.toaster.toasterTimeout();
  }

}
