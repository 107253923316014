export class Events{
  public static TriggerEvents: string[] = [
    // "tasknotesaddedevent",
    // "tasknotesupdatedevent",
    // "orderrequestedevent",
    "taskduedatechangedevent",
    "taskstartedevent",
    "ordercancelledevent",
    "taskreturnedtoigpsevent",
    "taskcancelledevent",
    "taskassignedevent",
    "taskunassignedevent",
    "taskreturnedtobranchevent",
    "tasksenttoqueryevent",
    "taskputonholdevent",
    "taskremoveholdevent",
    "taskescalatedtoonshoreevent",
    "taskcompletedevent",
    "taskcompletedwithexceptionsevent",
    "tasksenttoprocessorevent",
    // "taskdocumentaddedevent",
    // "taskdocumentremovedevent",
    // "taskdocumentphrasevalueupdatedevent"
    // "taskdocumentassociatepackageevent",
    // "errorreportedbyigniteevent",
    // "taskstartedactionerrorigniteevent",
    // "returntobranchactionerrorigniteevent",
    // "completedactionerrorigniteevent",
    // "completedwithexceptionsactionerrorigniteevent"
    "taskcancelledbyipsevent",
    // "createnewtaskevent",
    "emaillinkedtotaskevent",
    "taskunlinkfromemailevent",
    "emailstartedevent"
  ];
  public static NotesEvent: string[] = [
    "tasknotesaddedevent",
    "tasknotesupdatedevent",
    "errorreportedbyigniteevent",
    "emailnotesaddedevent",
    "emailnotesupdatedevent",
    "emailnotesstatuschangedevent",
    "emaillinkedtotaskevent",
    "emailmovedevent",
    "emailstartedevent"
  ];
  public static DocumentEvent: string[] = [
    "taskdocumentaddedevent",
    "taskdocumentremovedevent",
    "taskdocumentphrasevalueupdatedevent",
    "taskdocumentassociatepackageevent",
    "emaildocumentaddedevent"
  ];
  public static IgniteErrorEvent: string[] = [
    "taskstartedactionerrorigniteevent",
    "returntobranchactionerrorigniteevent",
    "completedactionerrorigniteevent",
    "completedwithexceptionsactionerrorigniteevent"
  ];
}
